import React, { useState } from 'react';
import './KnowledgeHub.css';
// import SmallDividerImage from '../../assets/images/landing_page_v2/small-divider.svg';
import FeaturedCards from './FeaturedCards/FeaturedCards';
import axios from 'axios';
import { useEffect } from 'react';

const KnowledgeHub = () => {
  const [usecaseData, setuseCaseData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUsecase = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://dicra-api-v2-dev.eastus.cloudapp.azure.com/api/v2/usecases/1`
      );

      if (res.status === 200 && res.data.items.length > 0) {
        setLoading(false);
        setuseCaseData(res.data.items);
      }
    } catch (err) {
      console.log();
    }
  };

  // Shuffle function to randomize an array
  // const shuffleArray = (array) => {
  //   const shuffledArray = [...array];
  //   for (let i = shuffledArray.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [shuffledArray[i], shuffledArray[j]] = [
  //       shuffledArray[j],
  //       shuffledArray[i],
  //     ];
  //   }
  //   return shuffledArray;
  // };

  // const shuffledUsecaseData = shuffleArray(usecaseData);
  const firstFourUsecaseData = usecaseData.slice(1, 5);

  useEffect(() => {
    getUsecase();
  }, []);

  return (
    <div className="kh-section-container" id="use-cases">
      <div className="kh-top-section">
        <div className="kh-top-left-section">
          <span>Knowledge Hub</span>
        </div>
        {/* <div className="kh-divider-section">
          <img src={SmallDividerImage} alt="divider" />
        </div> */}
        {/* <div className="kh-top-right-section">
          <span>
            Here, you will discover a treasure trove of articles, reports, and
            use cases dedicated to climate-resilient agriculture. Dive into our
            comprehensive collection of resources and gain valuable insights
            into sustainable farming practices and cutting-edge technologies.
          </span>
        </div> */}
      </div>
      <div className="kh-bottom-section">
        {loading ? (
          <div className="loader">Loading...</div>
        ) : (
          <FeaturedCards usecasedata={firstFourUsecaseData} />
        )}
      </div>
    </div>
  );
};

export default KnowledgeHub;
